<div class="container">
    <h2 class="my-4">Tabla de Insumos</h2>
    <table class="table table-bordered">
        <thead style="text-align: center;">
            <tr>
                <th>Nombre de los insumos</th>
                <th>Clave del producto</th>
            </tr>
        </thead>
        <tbody>
            <!-- Utilizamos *ngFor para iterar sobre maquinariaArray -->
            <tr *ngFor="let insumos of insumosArray">
                <td>{{ insumos.name }}</td>
                <!-- Utilizamos routerLink para generar el enlace dinámico -->
                <td><a [routerLink]="['/insumos', insumos.keySap]">{{ insumos.keySap }}</a></td>
            </tr>
        </tbody>
    </table>
</div>