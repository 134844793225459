<footer class="footer bg-dark text-white py-4" id="footer">
    <div class="container text-center">
        <div class="row">
            <div class="col-md-6">
                <h4>Síguenos en redes sociales:</h4>
                <div class="social-icons">
                    <a href="https://www.instagram.com/cafe.etrusca/" target="_blank" class="btn btn-link text-white">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.facebook.com/cafeetrusca" target="_blank" class="btn btn-link text-white">
                        <i class="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.youtube.com/user/cafeetrusca" target="_blank" class="btn btn-link text-white">
                        <i class="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <p>Visita nuestra página web:</p>
                <a href="https://cafeetrusca.com/" target="_blank" class="btn btn-primary">Ir a la página web</a>
            </div>
        </div>
    </div>
</footer>