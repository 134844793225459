<div *ngIf="insumo">
    <header>
        <div class="d-flex justify-content-between align-items-center">
            <img src="https://i.postimg.cc/y8j19qhw/logo.png" alt="Logo" id="logo">
            <!-- <img src="https://i.postimg.cc/kG6Dp3CM/imprimir-contorno-del-boton.png" alt="Logo" id="icon-print"> -->
        </div>
    </header>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="d-flex flex-column">
                    <h1 class="my-2" style="font-weight: 700; text-align: left;">{{ insumo.name }}</h1>
                    <h6 class="my-4"><b>Descripción:</b></h6>
                    <p style="text-align: justify">{{insumo.description}}</p>
                </div>
            </div>
            <!--mostrar imagenes-->
            <div class="col-md-6  ">
                <div class="row">
                    <!-- Carrusel principal -->
                    <div class="col-md-10">
                        <carousel #carousel>
                            <slide *ngFor="let url of extractedUrls" class="contenedor-imagen">
                                <img [src]="sanitizeUrl(url)" class="d-block w-100" [alt]="'Imagen '" 
                                [ngClass]="{'botella-torani': insumo.keySap.startsWith('IJA')}">
                            </slide>
                        </carousel>
                    </div>

                    <!-- Miniaturas en la derecha -->
                    <div class="col-md-2 d-none d-sm-block">
                        <div class="row">
                            <div class="col-12" *ngFor="let url of extractedUrls; let i = index">
                                <a href="javascript:void(0);" (click)="goToSlide(i)">
                                    <img [src]="sanitizeUrl(url)" class="img-thumbnail" [alt]="'Miniatura '">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>

            <table class="table my-5">
                <thead class="table-dark">
                    <tr>
                        <th colspan="2" style="text-align: center;">Más características</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="text-align: center;">
                        <th scope="row">Clave</th>
                        <td> {{ insumo.keySap }} </td>
                    </tr>
                    <!-- <tr>
                <th scope="row">Marca</th>
                <td *ngIf="matchingMarca &&  matchingMarca.name">{{ matchingMarca.name }}</td>
                <!-- <td><img *ngIf="matchingMarca && matchingMarca.image" [src]="matchingMarca.image" alt="Imagen de la marca"></td> 
              </tr> -->
                    <tr style="text-align: center;">
                        <th scope="row">Categoría</th>
                        <td> {{ insumo.category }}</td>
                    </tr>
                    <!-- <tr style="text-align: center;">
                <th scope="row">Sabor</th>
                <td>{{ insumo.flavor }}</td>
              </tr> -->
                    <tr style="text-align: center;">
                        <th scope="row">Contenido</th>
                        <td>{{ insumo.contNeto }}</td>
                    </tr>
                    <tr style="text-align: center;">
                        <th scope="row">Presentación</th>
                        <td>{{ insumo.salesUnit }}</td>
                    </tr>
                    <tr style="text-align: center;">
                        <th scope="row">Medidas</th>
                        <td> {{ insumo.width }}cm x {{ insumo.height }} cm </td>
                    </tr>
                    <tr style="text-align: center;">
                        <th scope="row">Peso</th>
                        <td> {{ insumo.weight }}gr</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="container my-4" id="contenedor-detalles-insumo">
            <ul class="no-bullets">
                <br>
                <!-- <li><b>Certificaciones: </b> {{ insumo.certification }}</li>
            <li><b>Observaciones: </b> {{insumo.observations}} </li> -->
            </ul>
        </div>
    </div>

    <div class="container d-flex flex-wrap justify-content-around align-items-center my-4">
        <svg class="codeBar my-3" id="codeBarInsumo"></svg>

        <div class="my-3">
            <qr-code [value]="qrCodeUrl" size="200" errorCorrectionLevel="M" display="image"></qr-code>
        </div>

        <div class="space" style="height: 1cm;"></div>
    </div>