<div class="container">
    <h2 class="my-4">Tabla de Accesorios</h2>
    <table class="table table-bordered">
        <thead>
            <tr style="text-align: center;">
                <th>Nombre del accesorio</th>
                <th>Clave del producto</th>
            </tr>
        </thead>
        <tbody>
            <!-- Utilizamos *ngFor para iterar sobre maquinariaArray -->
            <tr *ngFor="let accesorio of accesoriosArray">
                <td>{{ accesorio.name }}</td>
                <!-- Utilizamos routerLink para generar el enlace dinámico -->
                <td><a [routerLink]="['/accesorios', accesorio.keySap]">{{ accesorio.keySap }}</a></td>
            </tr>
        </tbody>
    </table>
</div>