<div class="container">
  <h2 class="my-4">Tabla de Máquinas</h2>
  <table class="table table-bordered">
    <thead style="text-align: center;">
      <tr>
        <th>Nombre de la máquina</th>
        <th>Clave del producto</th>
      </tr>
    </thead>
    <tbody>
      <!-- Utilizamos *ngFor para iterar sobre maquinariaArray -->
      <tr *ngFor="let maquina of maquinariaArray">
        <td>{{ maquina.name }}</td>
        <!-- Utilizamos routerLink para generar el enlace dinámico -->
        <td><a [routerLink]="['/maquinaria', maquina.keySap]">{{ maquina.keySap }}</a></td>
      </tr>
    </tbody>
  </table>
</div>