<div class="wrapper">
    <!-- Mensaje de cargando data -->
    <div *ngIf="(isLoading$ | async)">
        <app-loading-spiner></app-loading-spiner>
    </div>

    <!-- Resto de tu contenido -->
    <app-navbar ></app-navbar>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>