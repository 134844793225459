<div class="container my-3">
    <h1>Fichas técnicas</h1>
    <p>En este espacio puedes encontrar las fichas técnicas de los accesorios, maquinaria e insumos de Etrusca
        comercial.</p>
</div>

<div class="container d-flex justify-content-center mt-5">
    <a class="custom-button" routerLink="/maquinaria">Maquinaria</a>
</div>

<div class="container d-flex justify-content-center mt-3">
    <a class="custom-button" routerLink="/insumos">Insumos</a>
</div>

<div class="container d-flex justify-content-center mt-3">
    <a class="custom-button" routerLink="/accesorios">Accesorios</a>
</div>